import { dew as _ParserDew } from "./Parser.js";
import _domhandler from "domhandler";
import { dew as _TokenizerDew } from "./Tokenizer.js";
import _domelementtype from "domelementtype";
import { dew as _FeedHandlerDew } from "./FeedHandler.js";
import { dew as _StreamDew } from "./Stream.js";
import { dew as _WritableStreamDew } from "./WritableStream.js";
import { dew as _ProxyHandlerDew } from "./ProxyHandler.js";
import _domutils from "domutils";
import { dew as _CollectingHandlerDew } from "./CollectingHandler.js";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var Parser = _ParserDew();

  var DomHandler = _domhandler;

  function defineProp(name, value) {
    delete exports[name];
    exports[name] = value;
    return value;
  }

  exports = {
    Parser: Parser,
    Tokenizer: _TokenizerDew(),
    ElementType: _domelementtype,
    DomHandler: DomHandler,

    get FeedHandler() {
      return defineProp("FeedHandler", _FeedHandlerDew());
    },

    get Stream() {
      return defineProp("Stream", _StreamDew());
    },

    get WritableStream() {
      return defineProp("WritableStream", _WritableStreamDew());
    },

    get ProxyHandler() {
      return defineProp("ProxyHandler", _ProxyHandlerDew());
    },

    get DomUtils() {
      return defineProp("DomUtils", _domutils);
    },

    get CollectingHandler() {
      return defineProp("CollectingHandler", _CollectingHandlerDew());
    },

    // For legacy support
    DefaultHandler: DomHandler,

    get RssHandler() {
      return defineProp("RssHandler", (this || _global).FeedHandler);
    },

    //helper methods
    parseDOM: function (data, options) {
      var handler = new DomHandler(options);
      new Parser(handler, options).end(data);
      return handler.dom;
    },
    parseFeed: function (feed, options) {
      var handler = new exports.FeedHandler(options);
      new Parser(handler, options).end(feed);
      return handler.dom;
    },
    createDomStream: function (cb, options, elementCb) {
      var handler = new DomHandler(cb, options, elementCb);
      return new Parser(handler, options);
    },
    // List of all events that the parser emits
    EVENTS: {
      /* Format: eventname: number of arguments */
      attribute: 2,
      cdatastart: 0,
      cdataend: 0,
      text: 1,
      processinginstruction: 2,
      comment: 1,
      commentend: 0,
      closetag: 1,
      opentag: 2,
      opentagname: 1,
      error: 1,
      end: 0
    }
  };
  return exports;
}