import { dew as _WritableStreamDew } from "./WritableStream.js";
import _inherits from "inherits";
import { dew as _Dew } from "../";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  exports = Stream;

  var Parser = _WritableStreamDew();

  function Stream(options) {
    Parser.call(this || _global, new Cbs(this || _global), options);
  }

  _inherits(Stream, Parser);

  Stream.prototype.readable = true;

  function Cbs(scope) {
    (this || _global).scope = scope;
  }

  var EVENTS = _Dew().EVENTS;

  Object.keys(EVENTS).forEach(function (name) {
    if (EVENTS[name] === 0) {
      Cbs.prototype["on" + name] = function () {
        (this || _global).scope.emit(name);
      };
    } else if (EVENTS[name] === 1) {
      Cbs.prototype["on" + name] = function (a) {
        (this || _global).scope.emit(name, a);
      };
    } else if (EVENTS[name] === 2) {
      Cbs.prototype["on" + name] = function (a, b) {
        (this || _global).scope.emit(name, a, b);
      };
    } else {
      throw Error("wrong number of arguments!");
    }
  });
  return exports;
}