import { dew as _Dew } from "./";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  exports = CollectingHandler;

  function CollectingHandler(cbs) {
    (this || _global)._cbs = cbs || {};
    (this || _global).events = [];
  }

  var EVENTS = _Dew().EVENTS;

  Object.keys(EVENTS).forEach(function (name) {
    if (EVENTS[name] === 0) {
      name = "on" + name;

      CollectingHandler.prototype[name] = function () {
        (this || _global).events.push([name]);

        if ((this || _global)._cbs[name]) (this || _global)._cbs[name]();
      };
    } else if (EVENTS[name] === 1) {
      name = "on" + name;

      CollectingHandler.prototype[name] = function (a) {
        (this || _global).events.push([name, a]);

        if ((this || _global)._cbs[name]) (this || _global)._cbs[name](a);
      };
    } else if (EVENTS[name] === 2) {
      name = "on" + name;

      CollectingHandler.prototype[name] = function (a, b) {
        (this || _global).events.push([name, a, b]);

        if ((this || _global)._cbs[name]) (this || _global)._cbs[name](a, b);
      };
    } else {
      throw Error("wrong number of arguments");
    }
  });

  CollectingHandler.prototype.onreset = function () {
    (this || _global).events = [];
    if ((this || _global)._cbs.onreset) (this || _global)._cbs.onreset();
  };

  CollectingHandler.prototype.restart = function () {
    if ((this || _global)._cbs.onreset) (this || _global)._cbs.onreset();

    for (var i = 0, len = (this || _global).events.length; i < len; i++) {
      if ((this || _global)._cbs[(this || _global).events[i][0]]) {
        var num = (this || _global).events[i].length;

        if (num === 1) {
          (this || _global)._cbs[(this || _global).events[i][0]]();
        } else if (num === 2) {
          (this || _global)._cbs[(this || _global).events[i][0]]((this || _global).events[i][1]);
        } else {
          (this || _global)._cbs[(this || _global).events[i][0]]((this || _global).events[i][1], (this || _global).events[i][2]);
        }
      }
    }
  };

  return exports;
}