import { dew as _Dew } from "./";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  exports = ProxyHandler;

  function ProxyHandler(cbs) {
    (this || _global)._cbs = cbs || {};
  }

  var EVENTS = _Dew().EVENTS;

  Object.keys(EVENTS).forEach(function (name) {
    if (EVENTS[name] === 0) {
      name = "on" + name;

      ProxyHandler.prototype[name] = function () {
        if ((this || _global)._cbs[name]) (this || _global)._cbs[name]();
      };
    } else if (EVENTS[name] === 1) {
      name = "on" + name;

      ProxyHandler.prototype[name] = function (a) {
        if ((this || _global)._cbs[name]) (this || _global)._cbs[name](a);
      };
    } else if (EVENTS[name] === 2) {
      name = "on" + name;

      ProxyHandler.prototype[name] = function (a, b) {
        if ((this || _global)._cbs[name]) (this || _global)._cbs[name](a, b);
      };
    } else {
      throw Error("wrong number of arguments");
    }
  });
  return exports;
}